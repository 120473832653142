body {
  .refbtn {
    margin: 10px 0;
    font-size: 0.8em;
    font-weight: 600;
    margin-top: 1.5rem;

    @media (max-width: 855px) {
      margin-top: 3.5rem;
    }
    @media (max-width: 780px) {
      width: 380px;
    }
    @media (max-width: 500px) {
      font-size: 12px;
    }
    @media (max-width: 420px) {
      width: calc(95vw - 10vw) !important;
    }
  }

  .forbtn {
    text-transform: none;
    font-weight: 500;

    @media (max-width: 800px) {
      margin-top: 4px;
      margin-right: 15px;
      letter-spacing: 0px;
      font-size: 12px;
    }
  }

  #otpdia {
    mark {
      background: none;
      color: #336cfb;
      font-weight: 600;
    }
  }

  .crtbtn {
    width: 200px;
    color: #fefefe;
    background-color: #336cfb;
    text-transform: none;
    cursor: pointer;
    pointer-events: auto;
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 2rem;

    @media (max-width: 500px) {
      width: max-content;
      font-size: 12px;
    }

    &:disabled {
      color: #888;
      background-color: #cdcccc;
      cursor: not-allowed;
    }
  }

  .forpanel {
    padding: 12px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 600px;
    //  width: calc(100vw - 10vw);

    @media (max-width: 530px) {
      // padding: 10px !important;
    }
    .foremail {
      max-width: 440px;
      width: calc(80vw - 10vw) !important;
      // overflow-x: hidden;
      // background-color: #000;
      margin-top: 36px;
      margin-bottom: 48px;
      @media (max-width: 530px) {
        margin-top: 8px;
        margin-bottom: 20px;
      }
    }

    button {
      align-self: flex-end;
    }
  }

  .resetForm {
    .passInp {
      margin-bottom: 24px;
    }
  }
}

// ***************************  Signin Container *************************************

.auth__container {
  display: flex;
  width: 100vw;
  height: 100vh !important;
  min-height: 100vh !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

.authBanner {
  min-width: 360px;

  @media (max-width: 890px) {
    display: none;
  }
}

.signin--title {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.5px;

  @media (max-width: 500px) {
    font-size: 21px;
    width: calc(100vw - 10vw);
  }
}

.signin__container {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 50px;
  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-width: 890px) {
    align-items: center;
    margin-left: 0px;
  }
}

.already--signin {
  display: flex;
  justify-content: flex-end;
  @media (max-width: 890px) {
    justify-content: center;
  }
  @media (max-width: 500px) {
    font-size: 14px;
    margin-left: 0px;
  }
}

.already--signup {
  margin-left: -1rem;
  margin-top: 0.5rem;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  @media (max-width: 1155px) {
    margin-bottom: 1rem;
    justify-content: center;
  }
  @media (max-width: 855px) {
    margin-top: 5rem;
    //  margin-bottom: 1rem;
  }
  @media (max-width: 780px) {
    margin-top: 11rem;
    margin-left: 0.1rem;
  }

  @media (max-width: 500px) {
    font-size: 14px;
    margin-left: 0px;
  }
}
.toggleBtn {
  width: 380px;
  background-color: #245fd3 !important;
  margin-top: 20px !important;
  text-transform: none;
  font-size: 16px;
  max-width: 380px;

  @media (max-width: 420px) {
    width: calc(100vw - 10vw);
  }
}

.width-sm {
  width: 380px;
  max-width: 380px;
  @media (max-width: 420px) {
    width: calc(100vw - 10vw);
  }
}

.bottomDiv {
  width: 380px;
  min-width: 300px;
  @media (max-width: 420px) {
    width: calc(100vw - 10vw);
  }
}

.signin--checkbox--text {
  color: #b5b3c4;
  @media (max-width: 420px) {
    font-size: 12px;
  }
}

.loader--signin {
  //   position: absolute;
  //   width: 180px;
  width: 50%;
  margin: auto;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

// ***************************  Signup Container *********************************** //

.signup {
  overflow: hidden;
  display: flex;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1100px) {
    overflow: scroll;
  }
}
.signup__container {
  min-height: 100vh !important;
  height: 100vh;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1155px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  @media (max-width: 450px) {
    //  margin-top: 6rem;
    padding: 10px;
  }
}

.authBanner__signup {
  width: 360px;
  min-width: 360px;

  @media (max-width: 1230px) {
    width: 325px;
    min-width: 325px;
  }

  @media (max-width: 1190px) {
    width: 300px;
    min-width: 300px;
  }

  @media (max-width: 1170px) {
    display: none;
  }
}

.signup--title {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.5px;

  @media (max-width: 855px) {
    display: flex;
    justify-content: center;
    margin-right: 1rem;
  }

  @media (max-width: 500px) {
    font-size: 21px;
  }
}

.label--signup,
.label--signin {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 10px;
  margin-top: 20px;

  @media (max-width: 500px) {
    font-size: 14px !important;
    .MuiTypography-body1 {
      font-size: 14px !important;
    }
  }
}

.label--signup {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form__upper__section {
  display: flex;
  align-items: center;
  //   background-color: #000;
  @media (max-width: 780px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.signup__container--upper {
  margin-left: 50px;
  margin-top: 30px;

  @media (max-width: 1155px) {
    margin-left: 0px;
    margin-top: 0px;

    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  @media (max-width: 780px) {
    margin-left: 1rem !important;
  }
}

.signup__section--password {
  display: flex;
  align-items: center;
  max-height: 9rem;
  @media (max-width: 855px) {
    align-items: flex-start;
    flex-direction: column;
  }
}

.signup__section--passwordConfirm {
  position: relative;
  margin-left: 1rem;
  .MuiFormHelperText-root.Mui-error {
    position: absolute;
    top: 2.3rem;
  }

  @media (max-width: 855px) {
    margin-left: 0 !important;
    margin-bottom: 1rem;
  }
}

.error--text {
  color: red;
  font-size: 12px;
  right: 30%;
}
.checkbox__container {
  @media (max-width: 855px) {
    display: flex;
    width: 380px;
  }

  @media (max-width: 855px) {
    width: 330px;
  }
}

.checkbox__container--text {
  font-size: 12px;
  font-weight: 400;
  color: #b5b3c4;
}

.loader--signup {
  width: 50%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;

  position: absolute;

  @media (min-width: 1100px) {
    margin-top: 2rem;
  }
  @media (max-width: 780px) {
    margin-top: 1rem;
    left: 19%;
  }
}

// ********************************  Eye Icons ************************************ //

.eyeIcon--signin {
  position: absolute !important;
  top: 42% !important;
  right: 2% !important;

  @media (max-width: 890px) {
    position: absolute !important;
    top: 38% !important;
    right: 2% !important;
  }
}

.eyeIcon--signup {
  position: absolute !important;
  right: 0%;
  top: 48%;
}

.eyeIcon--signin--internal {
  position: absolute !important;
  top: 41%;
  right: 2%;
}

// ******************  Internal Signin Container **************************** //

.signin__container--internal {
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  margin-left: 50px;

  @media (max-width: 890px) {
    width: 90vw;
    height: 90vh;
    align-items: center;
    justify-content: center;
    margin-top: 0px;
  }

  @media (max-width: 500px) {
    margin-left: 1rem;
  }
}

.signin__btn--internal {
  width: 180px !important;
  background-color: #245fd3 !important;
  margin-top: 20px !important;
  text-transform: none;
  font-size: 16px !important;

  @media (max-width: 500px) {
    width: 120px !important;
    font-size: 14px !important;
  }
}

// *************************** Common Classes **************************** //

.auth__title {
  font-weight: 600;
  font-size: 28px;
  letter-spacing: 0.2px;

  @media (max-width: 500px) {
    font-size: 24px;
  }
}
