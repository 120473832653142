@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css2?family=Lato&family=Poppins&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif !important;
}
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  width: 100vw;
  font-family: Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100vw;
  overflow-x: hidden;
}

body::-webkit-scrollbar {
  width: 8px;
}

body::-webkit-scrollbar-thumb {
  width: 4px;
  border: solid 2px rgba(0, 0, 0, 0);
  border-radius: 8px;
  background-clip: content-box;
  background-color: #b1b1b1;
}

.handcr {
  cursor: pointer !important;

  * {
    pointer-events: none !important;
  }
}

.noscroll::-webkit-scrollbar {
  display: none;
}

.thinScroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.thinScroll:hover::-webkit-scrollbar-thumb{
  background: rgba(32, 24, 148, 0.4);
}

.thinScroll::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background-clip: content-box;
  background-color: #b1b1b1;
}
