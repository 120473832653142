.noShad {
  box-shadow: none !important;
}

.modPanel {
  padding: 44px 0 24px 64px !important;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    width: 4px;
    border: solid 2px rgba(0, 0, 0, 0);
    border-radius: 8px;
    background-clip: content-box;
    background-color: #696969;
  }
}

.actBtn {
  color: #fefefe !important;
  text-transform: none;
  box-shadow: none !important;

  * {
    pointer-events: none !important;
  }
}

.aprv {
  width: 120px !important;
  background-color: #30b52f !important;
  margin-right: 8px !important;
}

.decl {
  width: 120px !important;
  background-color: #f2563a !important;
}

.rmod {
  background-color: #185adb !important;
  width: 250px !important;
  margin-top: 10px !important;
}

.entCont {
  margin: 16px 0;

  &[data-hidden='true'] {
    display: none;
  }
}

.chHead {
  .MuiSvgIcon-root {
    width: 16px;
  }
}

.actOpt {
  .MuiFormControlLabel-root {
    margin-right: 36px;
  }

  textarea {
    width: 180px;
    font-size: 12px;
    border: solid 1px #aaa;
    padding: 2px 4px;
    border-radius: 4px;
    resize: none;
    opacity: 0;
    pointer-events: none;

    &:focus {
      outline: none;
    }
  }

  .c {
    pointer-events: auto;
    opacity: 1;
  }

  .MuiSvgIcon-root {
    width: 16px;
  }
}

.sbtn {
  padding: 8px 40px !important;
  color: #fefefe !important;
  background: #2e76e8 !important;
}

.MuiTypography-body1 {
  font-family: Poppins, sans-serif !important;
}

.intContainer {
  padding: 36px;
}
.intHeading {
  font-size: 40px;
  font-weight: 700;
  letter-spacing: 0.2px;
}

// ************************************* Table Container ************************************ //

.promoTable {
  margin-top: 2rem;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  border-radius: 8px;
}

.promoTable--cell {
  width: max-content !important;
}
.promoTable--head {
  font-weight: 700 !important;
  font-size: 14px !important;
  letter-spacing: 0.1px !important;
  height: 56px;
  background: #e8e8e840;
  padding: 10px;
  font-family: Poppins, sans-serif;
}

.promoTable--bodytext {
  color: #52575c !important;
  background: #ffffff !important;
  font-family: Lato, sans-serif !important;
  font-size: 14px !important;
  text-transform: capitalize;
  letter-spacing: 0.1px !important;
}

.paginationContainer {
  background-color: #ffffff;

  .MuiPaginationItem-page.Mui-selected {
    background-color: #336cfb;
    color: #ffffff;
  }

  .MuiPaginationItem-outlined {
    border: 1px solid #e8e8e8;
  }
}
