.pmColor {
  color: #336cfb;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.dashboard {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  height: max-content;
  overflow-x: hidden;

  *::selection {
    // background: inherit;
  }
}

.contBtn {
  background: #245fd3 !important;
  color: #fefefe !important;
  box-shadow: none !important;
  padding: 8px 12px !important;
  margin-bottom: 16px !important;
}

.uploadBtn {
  background-color: #336cfb !important;
  color: #fefefe !important;
  font-size: 14px !important;
  width: 160px !important;
  margin-left: 82px;
  text-transform: none !important;
}

.uploadedBtn {
  background-color: #27cc3a !important;
  color: #fefefe !important;
  font-size: 14px !important;
  width: 160px !important;
  margin-left: 82px !important;
  text-transform: none !important;
}

.plusCont {
  position: absolute;
  bottom: 12px;
  right: 15%;
}

#promoDesc:focus {
  outline: initial;
}

.tableCont {
  background: #fefefe;
  margin-top: 20;
  box-shadow: 0 12px 12px rgba(21, 27, 66, 0.05);
  border-radius: 6px;
}

.tableHead {
  background: rgba(210, 210, 210, 0.25);

  .tableCell {
    color: #222;
    font-weight: 600;
  }
}

.tableCell {
  padding: 16px 14px !important;
  color: #444 !important;
  font-family: Poppins, sans-serif !important;
}

.headCell {
  width: 96px !important;
}

.publbtn {
  width: 210px !important;
  color: #336cfb !important;
  background-color: #fefefe !important;
  text-transform: none !important;
  cursor: pointer !important;
  font-weight: 800 !important;
  pointer-events: auto !important;

  @media (max-width: 768px) {
    padding: 5px 5px !important;
    font-size: 13px !important;
    width: 160px !important;
  }

  @media (max-width: 645px) {
    margin-left: 1rem !important;
  }

  @media (max-width: 450px) {
    margin-left: 0.8rem !important;
  }

  @media (max-width: 400px) {
    width: 120px !important;
    margin-left: 1rem !important;
  }

  &:disabled {
    color: #888 !important;
    background-color: #cdcccc !important;
    cursor: not-allowed !important;
  }
}

.MuiFormHelperText-root {
  font-size: 0.64em !important;
}

.MuiInput-multiline {
  &::before {
    content: none !important;
  }
}

.wellHidden {
  position: absolute !important;
  height: 0;
  top: 2px;
  right: 0;
  width: 160px;
  pointer-events: none !important;

  .MuiInput-underline {
    &::after,
    &::before {
      content: none;
    }
  }
}

.locField {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .locInput {
    font-size: 16px;
    // padding: 8px 12px;

    margin: 12px 0 8px 0;
    // border: 1px solid #ccc;
    border-radius: 4px;
    width: calc(45vw - 10vw);
    max-width: 550px;

    input{
      padding: 14px 16px;
    }

    @media (max-width: 700px) {
      width: calc(100vw - 25vw);
    }

    @media (max-width: 500px) {
      font-size: 13px;
    }

    &:focus {
      border-width: 2px;
      border-color: #336cfb;
      outline: none;
    }
  }

  &:focus-within, &:focus{
    .locopts{
      display: block;
    }
  }

  *::selection{
    background: inherit;
  }

  .locopts {
    display: none;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
    width: calc(45vw - 10vw);
    max-width: 550px;
    max-height: 230px;
    overflow-y: scroll;
    overflow-x: hidden;

    @media (max-width: 700px) {
      width: calc(100vw - 25vw);
    }
    @media (max-width: 500px) {
      font-size: 13px;
    }
    .locopt {
      cursor: pointer;
      padding: 4px 8px;
      color: #444;
      border: solid 0 #ccc;
      border-width: 0 0 1px 0;
      width: calc(45vw - 10vw);
      max-width: 550px;
      @media (max-width: 700px) {
        width: calc(100vw - 25vw);
      }

      &[data-act='true'] {
        background: #336cfb;
        border-radius: 4px;
        color: #fefefe;
        width: 100%;
      }
    }
  }
}

.locWrp {
  background: #edf7fe;
  box-shadow: 4px 12px 24px rgba(51, 108, 251, 0.1);
  margin-top: 0.7rem;
  width: calc(44vw - 20vw);
  max-width: 500px;
  min-width: 255px;

  @media (max-width: 700px) {
    width: calc(100vw - 25vw);
  }
}

.locEnt {
  display: flex;
  padding: 8px 8px 8px 4px;
  margin-bottom: 2px;
  background: #fefefe;
  align-items: center;
  justify-content: space-between;

  .pmColor {
    width: 20px;
    margin-right: 8px;
  }

  .locname {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
// ************************************** Banner Preview ******************************************* //
// .root--bannerPreview {
//   padding: 26px;
//   margin-top: 0;
//   border-radius: 8px;
//   color: #fff;
//   background: linear-gradient(180deg, #353a3f 0%, #25282d 100%) !important;

//   @media (max-width: 415px) {
//     padding: 26px 14px;
//   }
// }
// ************************************** END ******************************************* //
.createForm__container {
  padding: 30px;
  border-radius: 8px;

  @media (max-width: 850px) {
    padding-left: 3.5rem;
    display: flex;
    flex-direction: column;
  }
  @media (max-width: 500px) {
    padding: 2rem;
  }
}

.service__promote {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;

  @media (max-width: 850px) {
    width: 100%;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
}

.service__promote--container {
  @media (max-width: 850px) {
    width: auto;
  }
}

.service__promote--textfield {
  .MuiOutlinedInput-root {
    width: calc(45vw - 10vw);
    max-width: 550px;
    margin-top: 10px;
    margin-bottom: 15px !important;

    @media (max-width: 600px) {
      width: calc(100vw - 25vw);
    }
    @media (max-width: 500px) {
      width: calc(100vw - 33vw);
      font-size: 13px;
    }
  }
}

// ************************************** Date and Promo ******************************************* //

.dateAndPromo {
  margin-left: 80px;
  width: 100%;

  .MuiSelect-outlined.MuiSelect-outlined {
    @media (max-width: 500px) {
      font-size: 13px;
    }
  }
  @media (max-width: 1250px) {
    margin-left: 50px;
  }
  @media (max-width: 600px) {
    margin-left: 0px !important;
  }
}

.dateAndPromoEnd {
  display: flex;

  @media (max-width: 700px) {
    flex-direction: column;
  }
}

.dateAndPromoEnd--rightSection {
  width: 100%;
  margin-left: 80px;

  @media (max-width: 1250px) {
    margin-left: 50px;
  }
  @media (max-width: 700px) {
    margin-left: 0px;
  }
}

.dateAndPromo--calender {
  width: 200px !important;
  margin-top: 10px !important;
  margin-bottom: 15px !important;

  @media (max-width: 500px) {
    .MuiOutlinedInput-inputMarginDense {
      font-size: 13px !important;
    }
    font-size: 13px !important;
  }
}

.menuItem {
  @media (max-width: 500px) {
    font-size: 13px !important;
  }
}

// ************************************** Stories Promo  ******************************************* //

.stories__promo {
  display: flex;
  width: 100%;

  @media (max-width: 850px) {
    flex-direction: column;
    justify-content: space-around;
    //  padding-left: 3.5rem;
  }
}

.stories__promo--info {
  width: calc(45vw - 10vw);
  max-width: 550px;
  min-width: 387px;
}

.stories__promo--details {
  margin-left: 80px;

  @media (max-width: 1250px) {
    margin-left: 50px !important;
  }
  @media (max-width: 850px) {
    margin-left: 0px !important;
    margin-top: 1.5rem;
  }
}

// ************************************** End ******************************************* //

.promoBudget {
  display: flex;
  align-items: center;

  @media (max-width: 400px) {
    min-width: 300px;
  }
}

.promoBudget--label {
  font-size: 17px;
  font-weight: bold;
  color: #336cfb;
  width: 200px;

  @media (max-width: 1250px) {
    width: 140px;
  }

  @media (max-width: 850px) {
    width: 205px;
  }

  @media (max-width: 400px) {
    min-width: 160px;
    font-size: 14px;
  }
}

.promoBudget--value {
  @media (max-width: 500px) {
    font-size: 13px;
  }
  .MuiOutlinedInput-input,
  .MuiButton-label {
    @media (max-width: 500px) {
      font-size: 13px;
    }
  }
}

.expectedAndPreview {
  //  display: flex;
  margin-top: 100px;
  //   margin-left: -6rem;

  @media (max-width: 925px) {
    flex-direction: column;
  }

  @media (max-width: 850px) {
    margin-top: 6rem;
  }
  @media (max-width: 450px) {
    margin-top: 3rem;
  }
  @media (max-width: 400px) {
    margin-left: -9px;
  }
}

.expectedAndPreview--duration {
  width: 360px;
  @media (max-width: 925px) {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 1rem;
  }
  @media (max-width: 925px) {
    //  align-items: center !important;
  }
}

.expectedAndPreview--story {
  width: 360px;
  margin-left: 100px;

  @media (max-width: 1250px) {
    margin-left: 80px;
  }

  @media (max-width: 1180px) {
    margin-left: 60px;
  }

  @media (max-width: 833px) {
    margin-left: 0rem !important;
  }

  @media (max-width: 780px) {
    margin-left: -3rem !important;
  }

  @media (max-width: 780px) {
    margin-left: 0 !important;
    margin-top: 1rem !important;
  }

  @media (max-width: 415px) {
    width: 21rem;
  }
}

.uploadBtn {
  color: #fff;
  border: none;
  font-size: 14px;
  width: 160px;
  line-height: 24px;
  margin-left: 82px;
  padding: 6px;
  border-radius: 3px;
  //   cursor: disable !important;
  text-transform: none;
}

// ****************************  Submit Section At Bottom  ****************************** //

.submitDiv {
  width: 100%;
  background-color: #336cfb;
  height: 124px;
  margin-top: 30px;
  border-radius: 8px;
  box-shadow: 0px 12px 26px rgba(16, 30, 115, 0.06);
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 950px) {
    margin-top: -1rem;
  }

  @media (max-width: 860px) {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 1rem;
  }

  @media (max-width: 600px) {
    margin-top: -3rem;
    margin-left: 1rem;
  }
  @media (max-width: 550px) {
    margin-top: -1.5rem;
  }

  @media (max-width: 400px) {
    padding-left: 1rem;
    padding-right: 2rem;
    padding-bottom: 0.2rem;
  }
}

.submitDiv--text {
  margin-top: 1rem;
  @media (max-width: 768px) {
    display: flex;
    align-items: center !important;
  }
  @media (max-width: 400px) {
    margin-top: 5px;
  }
}

// ********************************   Common Classes *********************************** //

.initialPadding {
  padding: 36px;
  @media (max-width: 1150px) {
    padding-left: 50px;
  }
  @media (max-width: 600px) {
    padding-left: 5px;
  }
}

.heading {
  font-size: 40px;
  font-weight: 700;
  @media (max-width: 1150px) {
    margin-left: 1.8rem;
    margin-top: -1.4rem;
  }
  @media (max-width: 850px) {
    padding-left: 1.3rem;
    margin-top: -1rem;
    text-align: center;
  }
  @media (max-width: 600px) {
    font-size: 30px;
    font-weight: 700;
  }
  @media (max-width: 500px) {
    font-size: 25px !important;
  }
  @media (max-width: 400px) {
    font-size: 18px !important;
    margin-top: -10px;
  }
}

.label {
  width: 100%;
  letter-spacing: 0.5px;
}

.bell {
  @media (max-width: 450px) {
    margin-left: 1px;
  }
}

// ******************************** Table Container *********************************** //

.tableContainer {
  @media (max-width: 600px) {
    margin-left: 1rem !important;
  }
}

// ************************ Story Preview Component **************************** //

.banner,
.story {
  width: 45rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  @media (max-width: 830px) {
    width: calc(94vw - 10vw);
    padding-left: 2rem;
    flex-direction: column;
    align-items: flex-start;
  }

  @media (max-width: 480px) {
    width: max-content;
    padding-left: 1rem;
  }

  @media (max-width: 425px) {
    padding-left: 0rem !important;
  }

  @media (max-width: 400px) {
    //  margin-left: -1.5rem;
  }
}

.storyPreview {
  margin-top: 1.5rem;
  margin-left: -6rem !important;

  @media (max-width: 1250px) {
    margin-left: -5rem !important;
  }

  @media (max-width: 1180px) {
    margin-left: -3.5rem !important;
  }

  @media (max-width: 830px) {
    margin-left: 2rem !important;
  }

  @media (max-width: 780px) {
    margin-left: 2.3rem !important;
  }
  @media (max-width: 480px) {
    margin-left: 0.2rem !important;
  }
  @media (max-width: 425px) {
    margin-left: 1rem !important;
  }
  @media (max-width: 395px) {
    margin-left: 1rem !important;
  }
}

.layoutPreview {
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: 525px) {
    margin-left: 1rem !important;
  }

  @media (max-width: 425px) {
    margin-left: -1rem !important;
  }
}
