.nav {
  @media (min-width: 960px) {
    display: none;
  }
}
.nav--icon {
  cursor: pointer;
  height: 45px;
  width: 45px;
  border-radius: 4px;
  padding: 12px 10px;
  position: fixed;
  top: 2%;
  left: 5%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  box-shadow: 0px 13px 25px 1px rgba(64, 123, 255, 0.21),
    0px 8px 16px rgba(159, 209, 255, 0.08);
  //   filter: blur(20px);

  z-index: 5;
  .MuiSvgIcon-root {
    font-size: 35px !important;
    fill: #185adb !important;
  }
}

.nav--logo {
  width: 3.5rem;
  height: 3.5rem;
}

.nav--container {
  padding-top: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav--horz__line {
  background: #185adb;
  height: 4rem;
  width: 1.5px;
  margin-right: 12px;
  margin-left: 10px;
}

.nav-logoText {
  font-family: 'Poppins', sans-serif !important;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.8px;
  color: #185adb;
}

.nav--drawerContainer {
  padding: 32px 50px;
  height: 100vh;
  overflow: hidden;
}

.line1,
.line2,
.line3 {
  background: #185adb;
  height: 3px;
  margin-bottom: 5px;
}

.line1 {
  width: 15px;
}
.line2 {
  width: 20px;
}
.line3 {
  width: 15px;
}

.nav--text {
  color: #52575c;
  height: 28px;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin: 36px 0px 20px;
  cursor: pointer;
}

.nav--btn {
  background: #245fd3 !important;
  color: white !important;
  box-shadow: 0px 4px 9px rgba(24, 90, 219, 0.4);
  border-radius: 4px;
  cursor: pointer;
  padding: 12px 21px !important;
  margin-bottom: 15px !important;
}

.homeBanner {
  width: 100%;
  height: 100%;

  @media (max-width: 769px) {
    width: 12rem;
  }
  @media (max-width: 550px) {
    width: 8rem;
  }
}

.home--txt1 {
  font-size: 16px !important;
  @media (max-width: 830px) {
    font-size: 13px !important;
  }
  @media (max-width: 550px) {
    font-size: 12px !important;
  }
}

.home--img1 {
  width: 330px;
  height: 130px;
  @media (max-width: 959px) {
    width: 80%;
    object-fit: cover;
  }
  @media (max-width: 750px) {
    width: 100%;
    height: 100%;
    object-fit: unset;
  }
}

.consumer {
  width: 450px;
  padding: 20px;
  border-radius: 20px;
  margin-left: 1rem;
  background: rgb(255, 255, 255);
  box-shadow: 0px 13px 25px 1px rgba(64, 123, 255, 0.1),
    0px 8px 16px rgba(159, 209, 255, 0.08);
  @media (max-width: 959px) {
    display: none !important;
  }
}

.section--whyEtark {
  width: 80%;
  margin: 20px 10%;
  padding: 20px;
  border-radius: 20px;
  margin-top: 30px;
  background: rgb(255, 255, 255);
  box-shadow: 0px 8px 16px rgba(159, 209, 255, 0.3);

  @media (max-width: 959px) {
    width: 89%;
    margin-left: 5.5%;
  }
  @media (max-width: 950px) {
    box-shadow: none;
  }

  @media (max-width: 750px) {
    width: 87%;
  }

  p {
    color: #336cfb;
    font-size: 13px;
    margin-bottom: 10px;
  }
}

.section--whyEtark-imgs01 {
  @media (max-width: 950px) {
    display: none;
  }
}

.section--whyEtark-imgs02 {
  @media (min-width: 950px) {
    display: none;
    width: 300px;
  }
  @media (max-width: 650px) {
    width: 350px;
  }
  @media (max-width: 430px) {
    width: 9rem;
  }
}

.section--whyEtark--imgContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  @media (max-width: 950px) {
    margin-left: -6%;
    margin-top: 0;
  }
  @media (max-width: 550px) {
    margin-left: -16%;
    margin-top: 1rem;
  }
  @media (max-width: 550px) {
    margin-left: -12%;
    margin-top: 1.5rem;
  }
}

.section--whyEtark--title {
  color: #52575c;
  font-size: 16px !important;
  margin-bottom: -2rem;
  @media (min-width: 680px) {
    margin-bottom: -4.5rem;
  }
  @media (max-width: 750px) {
    font-size: 13px !important;
  }
  @media (max-width: 430px) {
    margin-bottom: 1rem;
    //  font-size: 11px !important;
  }
}

.solutions--title {
  @media (max-width: 850px) {
    font-size: 14px !important;
  }
  @media (max-width: 850px) {
    font-size: 11px !important;
  }
}
.solutions--text {
  font-size: 14px !important;
  @media (max-width: 850px) {
    margin-left: 1px;
    font-size: 12px !important;
  }
  @media (max-width: 550px) {
    margin-left: 1px;
    font-size: 10px !important;
  }
}

.solution--icon {
  height: 18px !important;
  @media (max-width: 600px) {
    height: 12px !important;
  }
  @media (max-width: 600px) {
    height: 10px !important;
  }
}

.section--whyEtark-btns {
  position: relative;
}
.section--whyEtark-btns--right,
.section--whyEtark-btns--left {
  font-size: 36px !important;

  &:focus,
  &:active,
  &:hover,
  &:focus-visible,
  &:visited {
    border: none;
  }
  @media (max-width: 550px) {
    position: absolute;
    font-size: 25px !important;
  }
  @media (max-width: 400px) {
    font-size: 18px !important;
  }
}

.section--whyEtark-btns--left {
  margin-right: 20px;
  @media (max-width: 550px) {
    left: -3rem;
    top: -10px;
  }
}
.section--whyEtark-btns--right {
  margin-left: 20px;
  @media (max-width: 550px) {
    left: -10px;
    top: -10px;
  }
}

.section--bottom {
  width: 450px;
  color: #52575c;
}

.section--bottom--title {
  @media (max-width: 700px) {
    font-size: 14px !important;
  }
}

.section--bottom--text {
  margin-top: 10px;
  font-size: 11px;
  @media (max-width: 700px) {
    font-size: 9px;
  }
}

.div--bottom {
  width: 80%;
  margin: 20px 10%;
  padding: 10px;
  border-radius: 20px;
  background-color: #fff;
  margin-top: 60px;
  @media (max-width: 500px) {
    width: 98%;
    margin: 20px 1%;
    padding: 10px;
  }
}

.sales {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  font-weight: 700;
}

.sales--btns {
  margin-right: 0px;
  font-size: 36px;
  color: black;
  height: 3rem;
  display: flex;
  align-items: flex-end;
  @media (min-width: 959px) {
    display: none;
  }
  @media (max-width: 550px) {
    font-size: 25px;
  }
}

.sales--btns--left {
  margin-right: 2rem;
}

.bullet {
  color: #50555f;
  font-size: 11px !important;
  margin-right: 10px;
  margin-top: 6px !important;
}

.learn-more {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1rem;
  & p,
  a {
    color: #185adb;
    font-family: Poppins, sans-serif !important;
    font-size: 15px !important;
    font-weight: 500;
    line-height: 28px;

    @media (max-width: 450px) {
      font-size: 13px !important;
    }
  }

  @media (min-width: 959px) {
    display: none;
  }
}

.nav--accordion {
  color: #52575c;
  font-family: Poppins, sans-serif;
  font-weight: 500;
  font-size: 20px !important;
  line-height: 28px;
  letter-spacing: 0.02em;
  margin-top: 5px;
  margin-bottom: 5px;
  cursor: pointer;
}

.accordion {
  .MuiPaper-elevation1 {
    box-shadow: none !important;
  }
  .MuiAccordion-root {
  }
  .MuiAccordionSummary-content {
    margin: 0px !important;
  }
  margin-bottom: -1rem !important;
  overflow: hidden !important;
}

.accordion--content {
  display: flex;
  flex-direction: column;
  padding-bottom: 1rem;
  margin-top: -0.5rem;
  margin-bottom: -2.5rem;

  p,
  a {
    margin-bottom: 1.5rem;
    color: #185adb;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.3px;
  }
}

// *********************************** O N E S H O T ******************************** //

.oneshot {
  padding: 1rem 4rem;
  padding-bottom: 0;
  margin: auto;
  width: 90%;

  @media (max-width: 750px) {
    padding: 1rem;
  }

  span {
    display: flex;
    align-items: center;
    cursor: pointer;
    display: inline-flex;

    .MuiSvgIcon-root {
      fill: #336cfb;
      font-size: 20px;
    }
    p {
      margin-left: 0.5rem;
      color: #336cfb;
      font-family: 'Poppins', sans-serif !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.02em;
    }
  }

  h1 {
    margin-top: 2.5rem;
    font-family: 'Poppins', sans-serif !important;
    font-weight: bold;
    font-size: 52px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: #52575c;

    @media (max-width: 900px) {
      font-size: 40px;
    }
    @media (max-width: 750px) {
      font-size: 30px;
    }
  }
}

.oneshot-container {
  padding: 2rem 0.5rem;
  margin-top: 2rem;
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 900px) {
    margin-top: 1rem;
    overflow: hidden;
  }
}

.blur-radius {
  width: 100%;
  height: 200px;
  background: rgba(50, 36, 211, 0.47);
  filter: blur(170px);
  border-radius: 50%;
  position: absolute;
  bottom: 10%;
  z-index: -2;
}

.iframe-container {
  width: 100%;
  height: 400px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0px 8px 16px rgba(159, 209, 255, 0.9);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: blur(80px) !important;
    z-index: -1;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 4px;
  }
}

.os-tower1 {
  position: absolute;
  top: 48%;
  left: -12%;

  @media (max-width: 1000px) {
    height: 18rem;
    top: 70%;
    left: -20%;
  }
  @media (max-width: 750px) {
    height: 13rem;
    top: 70%;
    left: -25%;
  }
}

.os-tower2 {
  position: absolute;
  top: 45%;
  right: -15%;

  @media (max-width: 1000px) {
    height: 18rem;
    top: 50%;
    right: -25%;
  }
  @media (max-width: 750px) {
    height: 13rem;
    top: 67%;
    right: -23%;
  }
  @media (max-width: 550px) {
    height: 8rem;
    top: 67%;
    right: -23%;
  }
}

.oneshot-text-section {
  margin-top: 2rem;
  margin-bottom: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 420px) {
    margin-left: -1rem;
  }

  div {
    div {
      display: flex;
      align-items: center;

      span {
        font-family: 'Poppins', sans-serif !important;
        font-style: normal;
        font-weight: 300;
        font-size: 16px;
        line-height: 24px;
        letter-spacing: 0.02em !important;
        color: #52575c;
        margin-left: 1rem !important;
        width: 80%;
        margin-top: 0px;

        @media (max-width: 950px) {
          font-size: 14px;
        }
        @media (max-width: 750px) {
          font-size: 12px;
        }
        @media (max-width: 550px) {
          font-size: 10px;
        }
        @media (max-width: 550px) {
          line-height: 15px;
        }
      }
    }
  }
}

.mt {
  margin-top: 1rem !important;

  @media (max-width: 550px) {
    margin-top: 5px !important;
  }
}
.oneshot-png {
  height: 12rem;

  @media (max-width: 750px) {
    height: 8rem;
  }
  @media (max-width: 550px) {
    height: 6rem;
  }
}

.inquire-section {
  position: relative;
  background: #245fd3;
  width: 100%;
  height: 350px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  h1 {
    width: 714px;
    font-family: 'Poppins', sans-serif !important;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #f6f6f6;
    @media (max-width: 920px) {
      font-size: 22px;
      width: 500px;
    }
    @media (max-width: 520px) {
      font-size: 18px;
      width: 400px;
      line-height: 25px;
    }
    @media (max-width: 420px) {
      font-size: 16px;
      width: 300px;
      line-height: 25px;
    }
  }
}

.org-text {
  max-width: 75%;
  line-height: 25px !important;

  @media (max-width: 520px) {
    line-height: 18px !important;
    //  font-size: 10px;
  }
}

.inquire-tower1 {
  position: absolute;
  top: 21%;
  left: -2%;
  height: 22rem;
  @media (max-width: 880px) {
    height: 16rem;
    top: 38%;
    left: -4%;
  }
  @media (max-width: 600px) {
    height: 12rem;
    top: 40%;
    left: -6%;
  }
}
.inquire-tower2 {
  position: absolute;
  top: 18%;
  right: -1%;
  height: 18rem;
  @media (max-width: 880px) {
    height: 12rem;
    top: 33%;
    right: -3%;
  }
  @media (max-width: 600px) {
    height: 8rem;
    top: 40%;
    right: -6%;
  }
}

.modal {
  width: 950px;
  height: 400px;
  background: #fff;
  margin: auto;
  padding: 4.5rem 5.5rem;
  padding-bottom: 1rem;

  @media (max-width: 1000px) {
    width: calc(100vw - 5vw);
  }
  @media (max-width: 900px) {
    padding: 4.5rem 1rem;
  }
  @media (max-width: 750px) {
    height: 450px;
    padding-top: 6rem;
  }
  @media (max-width: 580px) {
    width: calc(100vw - 5vw);
  }
  @media (max-width: 420px) {
    height: 500px;
  }
}

.modal-content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    justify-content: space-between;

    //  margin-top: 5px;
    span {
      display: flex;
      align-items: center;

      p {
        font-weight: 600;
        font-size: 16px;
        line-height: 34px;
        letter-spacing: 0.02em;
        color: #000000;
        margin-left: 8px;
        width: 100%;

        @media (max-width: 750px) {
          font-size: 14px;
        }
        @media (max-width: 500px) {
          line-height: 14px;
          font-size: 12px;
        }
      }

      .MuiSvgIcon-root {
        font-size: 10px;
        @media (max-width: 750px) {
          font-size: 7px;
        }
      }
    }
  }
}

.margin {
  margin-bottom: 0.5rem !important ;

  @media (max-width: 750px) {
    margin-bottom: 0.9rem !important ;
  }
}

.success-page {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #26252e;
  }
  h3 {
    font-weight: normal;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    letter-spacing: 0.02em;
    color: #b5b3c4;
  }
}

.disabled-btn {
  color: white !important;
  background-color: darkgray !important;
}

.modal-close-icon {
  position: absolute;
  top: 4%;
  right: 2%;
  cursor: pointer;
}

.oneshot-err {
  color: red;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 1px;
  margin-top: -1rem;
  margin-bottom: 1rem;
}
