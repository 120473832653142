.imgCont {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background-color: #2d3135;
  overflow: hidden;
  box-shadow: -1px -1px 8px rgba(112, 113, 119, 0.25),
    1px 1px 8px rgba(13, 13, 15, 0.3),
    inset -1px -1px 5px rgba(102, 102, 109, 0.4),
    inset 1px 1px 5px rgba(16, 17, 18, 0.7);

  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
    margin-left: -1.2rem;
  }

  img {
    max-width: unset;
    height: 100%;
    // border-radius: 50%;
  }
}

svg * {
  pointer-events: none;
}

.horz {
  width: 160px;
  height: 250px;
  border-radius: 4px;
  @media (max-width: 500px) {
    width: 130px;
    height: 210px;
  }
}

.vert {
  width: 250px;
  height: 160px;
  border-radius: 4px;
  @media (max-width: 500px) {
    width: 210px;
    height: 130px;
  }
}

.portr {
  width: 250px;
  height: 250px;
  border-radius: 4px;
  @media (max-width: 500px) {
    width: 200px;
    height: 200px;
  }
}

.colinp {
  width: 80px;
  height: 80px;
  outline: none;
  border: none;
  padding: 0;
  margin: 0;
  background: none;
  border-radius: inherit;
  margin-top: -12px;
  margin-left: -12px;

  @media (max-width: 500px) {
    width: 60px;
    height: 60px;
  }
}

// ********************************  Layout Preview  *********************************** //

.layout1,
.layout2,
.layout3,
.layout4,
.layout5,
.layout6,
.layout7 {
  height: 560px;
  width: 330px;
  margin-top: 15px;
  border-radius: 8px;
  border: 10px solid #222129;
  box-shadow: 5px 5px 20px rgba(11, 12, 14, 0.6);

  @media (max-width: 400px) {
    height: 505px;
    width: 300px;
    border: 7px solid #222129;
  }
}

.layoutTextContainer {
  color: #edeff3;
  margin-top: 10px;
  padding: 24px;
  padding-right: 10px;
  text-align: left;
  display: flex;
  flex-direction: column;
}

.layoutTextContainer--title {
  font-size: 20px;
  font-weight: 700;
  word-break: break-word;
  @media (max-width: 500px) {
    font-size: 16px;
  }
}
.layoutTextContainer--description {
  font-size: 14px;
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 30px;
  word-break: break-word;
  @media (max-width: 500px) {
    font-size: 12px;
  }
}

.arrowForward {
  @media (max-width: 540px) {
    position: absolute;
    left: 11rem;
    bottom: -4rem;
  }
}

.arrowBackward {
  @media (max-width: 540px) {
    position: absolute;
    left: 5rem;
    bottom: -4rem;
  }
}
